.homepageContainer {
  width: 80%;
  margin-left: 10%;
  margin-top: 120px;
}
.flexEndContainer {
  display: flex;
  justify-content: flex-end;
}
.marginRight {
  margin-right: 20px;
}
.centerLoading {
  display: flex;
  justify-content: center;
}
.verticalCenter {
  margin-top: 20%;
}
.errorText {
  text-align: center;
  margin-top: 20%;
  color: red;
  opacity: 0.8;
  font-size: 20px;
}
.marginTop {
  margin-top: 10vh;
}
