.container {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.image_container {
  border: 2px solid #ccc;
  padding: 20px;
  cursor: pointer;
  width: 400px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons {
  margin: 10px !important;
}

.loader {
  margin: 10px;
}
