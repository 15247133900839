.appBarTitle {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.logo {
  padding-left: 20px;
}
.logo-image {
  margin-right: 20px;
}
.pageContainer {
  margin-top: 5em;
}
.white {
  color: white;
}
.textCenter {
  text-align: center;
}
