.centerLoading {
  display: flex;
  justify-content: center;
}
.custAdminTitleText {
  color: #106a9c;
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
