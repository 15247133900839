.container {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.pdf_container {
  border: 2px solid #ccc;
  padding: 20px;
  cursor: pointer;
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons {
  margin: 10px;
}

.fileName {
  margin: 5px 15px;
}

.icons {
  cursor: pointer;
}

.top_container {
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  width: 100%;
}

.loader {
  margin: 10px;
}

label {
  margin: 0px;
}
