.pageCenter {
  margin-top: 15%;
  text-align: center;
}
.whiteButton {
  background-color: white;
  color: #106a9c;
  padding: 10px;
  box-shadow: 2px 2px 5px 5px #d4d3d3;
  max-width: 25vw;
  margin: auto;
  margin-top: 20px;
}
.whiteButton:hover {
  background-color: rgb(173, 216, 230, 0.2);
}
.caregem-logo {
  width: 180px;
  margin: 5px;
}
.red {
  color: red;
}
